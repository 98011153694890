<template>
  <div class="main-container">
    <div class="result-btn mt20" v-if="creditCount === 3 || borrowCount === 3">
      <p class="mb20">刷脸结果处理中...</p>
      <van-button
        round
        block
        plain
        color="#4D7BFE"
        @click="getCreditFaceResult"
        v-if="creditCount === 3"
      >
        <span class="rem34">查看结果</span>
      </van-button>
      <van-button
        round
        block
        plain
        color="#4D7BFE"
        @click="getBorrowFaceResult"
        v-if="borrowCount === 3"
      >
        <span class="rem34">查看结果</span>
      </van-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import loadingIndex from '@/utils/overlay'
import { getfaceResultApi, getFaceTokenApi, getScanFaceUrlApi } from '@/apis/credit'
import { getFaceResultApi, getBorrowFaceTokenApi } from '@/apis/borrow'

import { mapState } from 'vuex'
import { Button } from 'vant'

Vue.use(loadingIndex)
Vue.use(Button)
export default {
  data() {
    return {
      creditCount: 0,
      creditTimer: '',
      borrowCount: 0,
      borrowTimer: '',
      faceBackUrl: window.location.origin,
    }
  },
  created() {
    if (window.location.href.indexOf('type=credit') != '-1') {
      this.getCreditFaceResult()
    } else if (window.location.href.indexOf('type=borrow') != '-1') {
      this.getBorrowFaceResult()
    }
  },
  computed: {
    ...mapState({
      businessType: (state) => state.common.businessType,
      productCode: (state) => state.common.productCode,
      creditApplySeqNo: (state) => state.borrow.creditApplySeqNo,
      creditFaceToken: (state) => state.borrow.creditFaceToken,
      applySeq: (state) => state.borrow.applySeq,
      faceToken: (state) => state.borrow.faceToken,
    }),
  },
  methods: {
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    // 获取授信刷脸结果
    getCreditFaceResult() {
      getfaceResultApi({
        applySeq: this.creditApplySeqNo,
        faceToken: this.creditFaceToken,
      })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.scFaceSts == 2) {
              if (res.data.scFaceTimes == 3) {
                this.$router.push('/wait-page')
                return
              }
              this.$common.toast('人脸识别失败，请重新进行人脸识别')
              this.toCreditFace()
            } else if (res.data.scFaceSts == 1) {
              this.$router.push('/wait-page')
            } else {
              if (!this.creditTimer) {
                this.creditTimer = setInterval(() => {
                  this.getCreditFaceResult()
                  this.creditCount += 1
                  if (this.creditCount == 3) {
                    clearInterval(this.creditTimer)
                  }
                }, 2000)
              }
            }
          } else if (res.code === 700) {
            // 超时重新查询
            if (!this.creditTimer) {
              this.creditTimer = setInterval(() => {
                this.getCreditFaceResult()
                this.creditCount += 1
                if (this.creditCount == 3) {
                  clearInterval(this.creditTimer)
                }
              }, 2000)
            }
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },
    toCreditFace() {
      getFaceTokenApi({
        applySeq: this.creditApplySeqNo, // 授信申请编号
        callbackUrl: this.faceBackUrl + '/face-result?type=credit', // 刷脸完成回调地址
      }).then((result) => {
        if (result.code === 200) {
          this.$store.commit('SET_CREDIT_FACE_TOKEN', result.data.faceToken)
          getScanFaceUrlApi(result.data.faceToken).then((r) => {
            if (r.code === 200) {
              window.location.href = r.data.originalUrl
            }
          })
        }
      })
    },
    // 获取借款刷脸结果
    getBorrowFaceResult() {
      getFaceResultApi({
        applySeq: this.applySeq,
        faceToken: this.faceToken,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.lcFaceSts == 2 || res.data.lcFaceSts == 31) {
            if (res.data.failTimes == 3) {
              this.$router.push('/face-recognition')
              return
            }
            this.$common.toast('人脸识别失败，请重新进行人脸识别')
            this.toBorrowFace()
          } else if (res.data.lcFaceSts == 1) {
            this.$router.push('/face-recognition')
          } else {
            if (!this.borrowTimer) {
              this.borrowTimer = setInterval(() => {
                this.getBorrowFaceResult()
                this.borrowCount += 1
                if (this.borrowCount == 3) {
                  clearInterval(this.borrowTimer)
                }
              }, 2000)
            }
          }
        } else if (res.code === 700) {
          if (!this.borrowTimer) {
            // 超时重新查询
            this.borrowTimer = setInterval(() => {
              this.getBorrowFaceResult()
              this.borrowCount += 1
              if (this.borrowCount == 3) {
                clearInterval(this.borrowTimer)
              }
            }, 2000)
          }
        }
      })
    },
    toBorrowFace() {
      getBorrowFaceTokenApi({
        applySeq: this.applySeq,
        callbackUrl: this.faceBackUrl + '/face-result?type=borrow',
      }).then((result) => {
        if (result.code === 200) {
          this.$store.commit('SET_FACE_TOKEN', result.data.faceToken)
          getScanFaceUrlApi(result.data.faceToken)
            .then((r) => {
              this.hideloading()
              window.location.href = r.data.originalUrl
            })
            .catch(() => {
              this.hideloading()
            })
        }
      })
    },
  },
  beforeDestroy() {
    this.creditCount = 0
    this.borrowCount = 0
    clearInterval(this.creditTimer)
    clearInterval(this.borrowTimer)
  },
}
</script>
<style lang="less" scoped></style>
